<template>
  <div class="extension_advantage_container w">
    <div class="extension_advantage_title">六大优势</div>
    <div class="extension_advantage_underline"></div>
    <div class="extension_advantage_entitle">Six major advantages</div>
    <div class="advantage_advantage_list">
      <div class="advantage_advantage_item" v-for="item in 6" :key="item">
        <img :src="getAdvantageImg(item)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtensionAdvantage',
  methods: {
    getAdvantageImg(index) {
      return require(`@/assets/images/extension/extension_advantage_${index}.png`)
    }
  }
}
</script>

<style lang="less" scoped>
.extension_advantage_container {
  padding: 112px 179px 208px 181px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .extension_advantage_title {
    font-size: 53px;
    font-family: Helvetica;
    color: #171717;
    line-height: 64px;
  }
  .extension_advantage_underline {
    width: 1289px;
    border: 1px solid #010343;
  }
  .extension_advantage_entitle {
    font-size: 21px;
    font-family: Helvetica;
    color: #171717;
    line-height: 25px;
    margin-bottom: 76px;
  }
  .advantage_advantage_list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .advantage_advantage_item {
      width: 224px;
      height: 327px;
    }
  }
}
</style>
