<template>
  <div class="extension_function_container w">
    <div class="extension_function_title">
      十二大特色辅助功能
    </div>
    <div class="extension_function_underline"></div>
    <div class="extension_function_entitle">
      Twelve features of auxiliary functions
    </div>
    <div class="extension_function_list">
      <div class="extension_function_item" v-for="item in 12" :key="item">
        <img :src="getFunctionImg(item)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtensionFunction',
  methods: {
    getFunctionImg(index) {
      return require(`@/assets/images/extension/extension_function_${index}.png`)
    }
  }
}
</script>

<style lang="less" scoped>
.extension_function_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 57px 181px 55px 181px;
  background: #f7f9fd;

  .extension_function_title {
    font-size: 53px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 75px;
    margin-bottom: 7px;
  }
  .extension_function_underline {
    width: 1289px;
    border: 1px solid #010343;
  }
  .extension_function_entitle {
    font-size: 21px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010343;
    line-height: 29px;
    margin-bottom: 81px;
  }
  .extension_function_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .extension_function_item {
      width: 224px;
      height: 327px;
      margin-bottom: 97px;
    }
  }
}
</style>
