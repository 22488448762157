<template>
  <div class="extension_understand_container w">
    <div class="extension_understand_left">
      <div class="logo">
        <img src="@/assets/images/extension/extension_logo.png" alt="" />
      </div>
      <div class="ch_1">三分钟带你了解拓站系统</div>
      <div class="en_1">
        Three minutes to understand the extension station system
      </div>
      <div class="ch_2">拓商业格局</div>
      <div class="en_2">Business structure</div>
      <div class="ch_2">站事业巅峰</div>
      <div class="en_2">Business structure</div>
    </div>
    <div class="extension_understand_right">
      <img src="@/assets/images/extension/extension_1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: ''
}
</script>

<style lang="less" scoped>
.extension_understand_container {
  display: flex;
  padding-top: 125px;
  .extension_understand_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 183px;
    margin-right: 155px;
    .logo {
      width: 217px;
      height: 64px;
      margin-top: 16px;
      margin-bottom: 55px;
    }
    .ch_1 {
      font-size: 53px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 75px;
      margin-bottom: 8px;
    }
    .en_1 {
      font-size: 21px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #000000;
      line-height: 29px;
      margin-bottom: 51px;
    }
    .ch_2 {
      font-size: 48px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 67px;
      margin-bottom: 3px;
    }
    .en_2 {
      font-size: 27px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
      margin-bottom: 29px;
    }
  }
  .extension_understand_right {
    width: 700px;
    height: 665px;
    margin-bottom: 31px;
  }
}
</style>
