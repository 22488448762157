<template>
  <div class="extension_content_container w">
    <div class="extension_left">
      <div class="title">泉邦拓站</div>
      <div class="introduction" v-for="item in extensionList" :key="item.index">
        <ExtensionIntroduction :index="item.index">
          <template v-slot:title_content>
            {{ item.title }}
          </template>
          <template v-slot:introduction>
            <div v-html="item.content"></div>
          </template>
        </ExtensionIntroduction>
      </div>
    </div>
    <div class="extension_right">
      <div class="title">轻松实现企业量化管理</div>
      <div class="extension_right_item">
        <div class="item_decription">
          <div class="item_decription_title">
            01 营销裂变系统
          </div>
          <div class="item_decription_content">
            会员储值、助力众筹、扭蛋抽奖、口碑排行、套餐卡…
            无限量营销裂变工具和方案组合供你选择，强大的咨询服务团队支持，持续不断迭代。
          </div>
        </div>
        <div class="item_image">
          <img src="@/assets/images/extension/extension_1.png" alt="" />
        </div>
      </div>
      <div class="extension_right_item">
        <div class="item_image">
          <img src="@/assets/images/extension/extension_2.png" alt="" />
        </div>
        <div class="item_decription">
          <div class="item_decription_title">
            02 团队激励系统
          </div>
          <div class="item_decription_content">
            通过数据化分析，激发员工竞争，明确门店问题，清晰工作目标，最大化激发员工主观能动性，让员工和老板一样思考
          </div>
        </div>
      </div>
      <div class="extension_right_item">
        <div class="item_decription">
          <div class="item_decription_title">
            03 合伙人激励系统
          </div>
          <div class="item_decription_content">
            合伙人分级别管理、权益商品赠送激励；各种组合方案激发合伙人转介绍，明确客户归属，账单报告一键生成，节约管理服务成本。
          </div>
        </div>
        <div class="item_image">
          <img src="@/assets/images/extension/extension_3.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtensionIntroduction from './extension-introduction.vue'
export default {
  name: 'ExtensionContent',
  components: {
    ExtensionIntroduction
  },
  data() {
    return {
      extensionList: [
        {
          index: 1,
          title: '什么是拓站',
          content:
            '工欲善其事，必先利其器，拓站系统是一款让你轻松营销，简单盈利的商业模式量化产品系统，以最先进的数据处理系统结合丰富的实战案例经验，根据店铺的实际经营情况和发展阶段，一站式解决实体店经营困境，实现轻松躺赚。'
        },
        {
          index: 2,
          title: '拓站能为你解决什么问题？',
          content:
            '拓站系统作为一款专为线下店铺量身定制的营销利器；全方位地为您解决客流不足、转化率低、渠道单一、内部管理及消费升级等常见问题，还可以帮您定制各种有效的营销方案，更可以解决连锁加盟管理难题；帮助店铺实现渠道多元化、数据可视化、盈利最大化。'
        },
        {
          index: 3,
          title: '怎么使用拓站系统？',
          content:
            '1.在咨询的过程中将店铺所面临的问题，想要达成的目标，真实集中地反馈，做到有的放矢；<br/>2.根据咨询师的定制方案及实施步骤，有效执行推进；<br/>3.实时反馈执行进程，取得初步成果后，设立下一阶段目标。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.extension_content_container {
  display: flex;
  padding-bottom: 106px;
}
.extension_left {
  width: 757px;
  height: 1329px;
  padding: 43px 49px 0 36px;
  margin-left: 185px;
  margin-right: 20px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
  }
}
.extension_right {
  width: 776px;
  height: 1329px;
  padding-top: 43px;
  .title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
    margin-bottom: 22px;
  }
  .extension_right_item {
    display: flex;
    margin-top: 60px;
    margin-right: 19px;
  }
  .item_decription {
    .item_decription_title {
      width: 435px;
      height: 49px;
      line-height: 49px;
      font-size: 32px;
      font-weight: 500;
      color: #010343;
      border-bottom: 5px solid #1651dd;
    }
    .item_decription_content {
      width: 435px;
      height: 228px;
      background: #f7f9fd;
      padding: 48px 16px 47px 20px;
      margin-top: 39px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 33px;
    }
  }
  .item_image {
    width: 304px;
    height: 291px;
    margin-right: 21px;
    margin-left: 21px;
    margin-top: 27px;
  }
}
</style>
