<template>
  <div class="extension_introduction_container">
    <div class="title">
      <div class="title_index">
        {{ index }}
      </div>
      <div class="title_content">
        <slot name="title_content"></slot>
      </div>
    </div>
    <div class="introduction">
      <slot name="introduction"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExtensionIntroduction',
  props: {
    index: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.extension_introduction_container {
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    margin-top: 41px;

    .title_index {
      width: 85px;
      height: 85px;
      line-height: 85px;
      text-align: center;
      background: #1651dd;
      font-size: 32px;
      font-weight: 500;
      color: #ffffff;
    }
    .title_content {
      width: 587px;
      height: 85px;
      line-height: 85px;
      background: #f7f9fd;
      font-size: 32px;
      font-weight: 500;
      color: #010343;
      padding-left: 36px;
    }
  }
  .introduction {
    width: 672px;
    height: 247px;
    background: #f7f9fd;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 33px;
    padding: 41px 45px 39px 36px;
    margin-top: 21px;
  }
}
</style>
