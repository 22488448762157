<template>
  <div class="extension_station_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!-- 拓站标题 -->
    <QbTitle
      :title="title"
      :complement="complement"
      :complementChangeStyle="true"
    ></QbTitle>

    <!-- 拓站系统了解 -->
    <ExtensionUnderstand></ExtensionUnderstand>

    <!-- 拓站系统内容 -->
    <ExtensionContent></ExtensionContent>

    <!-- 拓站特色功能 -->
    <ExtensionFunction></ExtensionFunction>

    <!-- 拓站优势 -->
    <ExtensionAdvantage></ExtensionAdvantage>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import ExtensionUnderstand from './components/extension-understand.vue'
import ExtensionContent from './components/extension-content.vue'
import ExtensionFunction from './components/extension-function.vue'
import ExtensionAdvantage from './components/extension_advantage.vue'
export default {
  name: 'ExtensionStation',
  components: {
    ExtensionUnderstand,
    ExtensionContent,
    ExtensionFunction,
    ExtensionAdvantage
  },
  data() {
    return {
      title: '《拓站营销系统》',
      complement: '互联网裂变管理 可视化营销交互'
    }
  }
}
</script>

<style lang="less" scoped></style>
