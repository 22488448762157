import { render, staticRenderFns } from "./extension-function.vue?vue&type=template&id=08883bcb&scoped=true&"
import script from "./extension-function.vue?vue&type=script&lang=js&"
export * from "./extension-function.vue?vue&type=script&lang=js&"
import style0 from "./extension-function.vue?vue&type=style&index=0&id=08883bcb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08883bcb",
  null
  
)

export default component.exports