import { render, staticRenderFns } from "./extension_advantage.vue?vue&type=template&id=248a2342&scoped=true&"
import script from "./extension_advantage.vue?vue&type=script&lang=js&"
export * from "./extension_advantage.vue?vue&type=script&lang=js&"
import style0 from "./extension_advantage.vue?vue&type=style&index=0&id=248a2342&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248a2342",
  null
  
)

export default component.exports